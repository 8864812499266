@import "./mixins.scss";
@import "./variable.scss";
@import "./helper.scss";
@import "./font.scss";
@import "~antd/dist/antd.css";
@import "./antd-override.scss";

body {
  margin: 0;
  padding: 0 !important;
  line-height: 1.3 !important;
  font-family: "Modernist-Regular";
  color: #FFFFFF !important;
  background: #17173E !important;
  overflow-x: hidden !important;
}

.app-container {
  max-width: 1160px;
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b6b6b6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html {
  scroll-behavior: smooth;
}

.table-container::-webkit-scrollbar {
  height: 10px;
}
.table-container::-webkit-scrollbar-thumb {
  background: #393964;
}
.table-container::-webkit-scrollbar-track {
  background: #0D0D26;
}
.flex_overflow{
  overflow-x: auto;
}
// .scrollable-content {
//   flex: 1; /* Allow the content to take available space */
//   white-space: nowrap; /* Prevent line breaks for inline elements */
// }

// .fixed-element {
//   flex-shrink: 0; /* Prevent the fixed element from shrinking */
// }
.mobile_stack_card::-webkit-scrollbar {
  height: 10px;
}
.mobile_stack_card::-webkit-scrollbar-thumb {
  background: #393964;
}
.mobile_stack_card::-webkit-scrollbar-track {
  background: #0D0D26;
}