.ant-popover-arrow {
  display: none !important;
}
.connect-wallet-popup {
  .ant-popover-inner-content {
    background: #393964 !important;
    width: 478px;
    padding: 20px 20px 26px !important;
  }
  
  @media (max-width: 575px) {
    left: 10px !important;
    width: calc(100% - 20px) !important;
    .ant-popover-inner-content {
      width: 100%;
      padding: 33px 15px 30px 20px !important;
    }
    .mobile_desc {
      width: 211px;
    }
  }
}

.active-area-popup {
  width: 254px;
  height: 76px;
  background: #ffffff;
  box-shadow: 0px 9px 22px rgba(0, 0, 0, 0.25);

  .ant-popover-inner-content {
    padding: 5px 16px;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-popover-inner {
    border-radius: 0;
  }
}

.more-icon-popup {
  padding-top: 0 !important;

  .ant-popover-inner-content {
    background: #393964 !important;
  }
  @media (max-width: 575px) {
    .ant-popover-inner-content {
      padding: 12px !important;
      width: 202px;
    }
    .ant-popover-inner {
      margin-right: 10px !important;
    }
    .m_head {
      margin-left: 10px !important;
    }
  }
}

.stake-nft-modal {
  .ant-modal-close-x,
  .ant-modal-header {
    display: none;
  }
  .ant-modal-content {
    background: transparent !important;
    color: white;
    box-shadow: none;
  }
  .ant-modal-body {
    padding: 0;
    position: relative;
    height: calc(100vh - 100px);
  }
}
.ant-modal-mask {
  background-color: rgba(23, 23, 62, 0.8) !important;
}

.staking-request-modal {
  .ant-modal-close-x,
  .ant-modal-header {
    display: none;
  }
  .ant-modal-content {
    background: transparent !important;
    color: white;
    box-shadow: none;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .main_part {
    top: 25px !important;
    left: 8px;
  }
  @media (max-width: 575px) {
    .mobile_btns {
      display: grid !important;
      justify-content: center;
    }
    .modal_border {
      top: -12px !important;
    }
    .mobile_width {
      width: 250px;
    }
    .main_part {
      top: -30px !important;
      left: 0 !important;
    }
    .mobile_position {
      left: -55px;
      top: -45px;
    }
    .ant-modal-body {
      padding: 24px !important;
    }
    .ant-modal-content {
      height: 444px;
      margin: 0 auto;
      width: 330px;
      padding: 20px;
    }
  }
}

.menu-dropdown {
  background-color: #393964 !important;
  width: 179px;
  height: 268px;
  padding: 14px 12px;
  margin-top: 15px;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: white !important;
    padding: 7px 5px;
  }
  .ant-dropdown-menu-item:hover {
    background-color: #393964;
  }
}

// .slider-1 {
//   .ant-slider-track {
//     border: 2px solid #fd9ea6 !important;
//   }
//   .ant-slider-rail {
//     border: 2px solid #F61A29;
//   }
// }
// .slider-2 {
//   .ant-slider-track {
//     border: 2px solid #ecdfb1 !important;
//   }
//   .ant-slider-rail {
//     border: 2px solid #F1D36B;
//   }
// }
// .slider-3 {
//   .ant-slider-track {
//     border: 2px solid #79d89c !important;
//   }
//   .ant-slider-rail {
//     border: 2px solid #0CAE48;
//   }
// }

.stake_coin_slider {
  .ant-slider-track {
    border: 2px solid #44B8CD !important;
  }
  .ant-slider-rail {
    border: 2px solid #5DD1DF;
  }
}

.ant-slider-track {
  height: 12px;
}
.ant-slider-rail {
  height: 12px;
  background-color: #393964;
}
.ant-slider:hover .ant-slider-rail {
  background: #393964;
}
.ant-slider-handle:focus {
  box-shadow: none;
}

.menu-drawer {
  height: 900px;
  
  .menu_data{
    padding-bottom: 20px;
    color: rgba(255, 255, 255, 0.7);
  }

  .ant-drawer-header-close-only {
    display: none;
  }
  .ant-drawer-body{
    padding: 0  ;
  }
  .ant-drawer-wrapper-body {
  background-color: #17173E;
  }
}

// countdown timer
.ant-statistic-content-value {
  color: white;
  font-family: 'Borken-console-bold';
}

.ant-message {
  font-family: 'Borken-console-bold';
}