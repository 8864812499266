$colors: (
  white: #fff,
  light: #F1F3F4,
  darkgray: #373F43,
  gray:#F1F3F4,
  gray_checkmark:#8C9098,
  lightgray: rgba(255, 255, 255, 0.7),
  battleyellow: #F1D36B
);
$maxLoop: 100;
$step: 5;
