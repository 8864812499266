@font-face {
  font-family: "Borken-console-regular";
  src: url("./../font/Broken\ Console\ Regular.ttf");
  src: url('./../font/Broken\ Console\ Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: "Borken-console-bold";
  src: url("./../font/Broken\ Console\ Bold.ttf");
  src: url('./../font/Broken\ Console\ Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: 'VT323-Regular';
  src: url("./../font/VT323-Regular.ttf");
  src: url('./../font/VT323-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}